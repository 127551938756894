import {
  type Unpacked,
  type ProductsQuery,
  type ProductsQueryVariables,
  TypedDocumentString,
  type ProductListingType,
  ProductsDocument,
  DiscountedProductsDocument,
} from '@robustastudio/e-commerce/common';
import { omit } from 'lodash-es';
import type { ProductCardFragment } from '~/graphql/fragments';
import type { MaybeReactive } from '~/types/utils';

type Maybe<T> = T | null;
/**
 * @description app middleware mapper in order to reconcile the product card type fetched in the app queries and the ones in the core.
 * @example
 */
export const appProductReconciliation = (item: ProductCardFragment) => ({
  ...omit(item, '__typename'),
  __typename: item.__typename === 'GiftCardProduct' ? 'SimpleProduct' : item.__typename,
});

export const appProductsMapper = (items: Maybe<ProductCardFragment>[]) =>
  toNonNullable(items)
    ?.map(appProductReconciliation)
    ?.map(item => ({ ...mapProductListing(item), ...appMapProductListing(item) })) || [];

export type ProductNode = Unpacked<ReturnType<typeof appProductsMapper>>;
/**
 * Application custom product mapper.
 */
const appMapProductListing = (item: ProductCardFragment) => {
  return {
    brand: {
      image: item.brand?.image_url || '',
      name: item.brand?.name || '',
      url: item.brand?.url_key || '',
    },
    description: item.short_description || '',
    isNew: !!item.is_new,
    brandImage: item.brand?.image_url || '',
    isCommercial: item?.is_commercial_product === 1,
  };
};

const mapper = (
  node: Node,
): {
  brandImage: string;
  isNew: boolean;
  categories: { id: number; name: string; url: string }[];
  horsePower: string;
  attributeSet: string;
  isCommercial: boolean;
} => ({
  brandImage: node?.brand?.image ?? '',
  isNew: !!node.isNew,
  categories: node?.categories ?? [],
  horsePower: node?.attributes?.find(attribute => attribute?.key === 'ac_horse_power')?.value || '',
  attributeSet: node?.attribute_set?.name || '',
  isCommercial: !!node.is_commercial_product,
});

export type Node = NonNullable<NonNullable<ProductsQuery['connection']>['nodes']>[number] & {
  brand: Maybe<{ name: string; image: string }>;
  isNew: Maybe<boolean>;
  categories: Maybe<{ name: string; url: string; id: number }[]>;
  attribute_set: Maybe<{ id: string; name: string }>;
  is_commercial_product: Maybe<number>;
};

export type ListingFullData = ReturnType<typeof useExtendableProductsGeneric>['data'];

export const useExtendedFetchProduct = <TResult, TVariables>(
  document: TypedDocumentString<TResult, TVariables>,
  variables: MaybeReactive<ProductsQueryVariables>,
) => {
  const extendedQuery = ExtendedProductDocument(
    document as any,
    'brand',
    `{
      name 
      image: image_url 
    }
    categories {
      name
      url: url_key
      id
    }
    attribute_set {
        id
        name
      }
    is_commercial_product
    `,
  );
  type ExtendedProductQuery = {
    __typename: ProductsQuery['__typename'];
    connection: {
      __typename: NonNullable<ProductsQuery['connection']>['__typename'];
      nodes: Array<Node | null | undefined> | null | undefined;
      aggregation: NonNullable<NonNullable<ProductsQuery['connection']>['aggregations']>;
    };
  };
  const { products, data, totalCount, isFetching } = useExtendableProductsGeneric<
    Node,
    ExtendedProductQuery,
    ReturnType<typeof mapper>
  >(extendedQuery, mapper, variables);

  return {
    products,
    totalCount,
    isFetching,
    data,
  };
};

export const useGenericExtendedFetchProduct = (
  type: ProductListingType,
  variables: MaybeReactive<ProductsQueryVariables>,
) => {
  if (type === 'default') {
    return useExtendedFetchProduct(ProductsDocument, variables);
  }

  if (type === 'discounted') {
    return useExtendedFetchProduct(DiscountedProductsDocument, variables);
  }

  return useExtendedFetchProduct(ProductsDocument, variables);
};

export type ProductItem = Unpacked<ReturnType<typeof useExtendedFetchProduct>>['products']['value'][number];
